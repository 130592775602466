import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AuthApi } from './apis/AuthApi';
import AlertBoxReducer from "./slice/AlertBoxSlice";
import AuthSliceReducer from './slice/AuthSlice';
import userRoleReducer from './slice/userRoleSlice';
import { DashboardApi } from './apis/DashboardApi';
import { MicroServiceApi } from './apis/MicroServiceApi';
import { UserManagementApi } from './apis/UserManagementApi';

const store = configureStore({
  reducer: {
    userRole: userRoleReducer,
    alertbox: AlertBoxReducer,
    auth: AuthSliceReducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [MicroServiceApi.reducerPath]: MicroServiceApi.reducer,
    [UserManagementApi.reducerPath]: UserManagementApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(AuthApi.middleware)
      .concat(DashboardApi.middleware)
      .concat(MicroServiceApi.middleware)
      .concat(UserManagementApi.middleware),

});
setupListeners(store.dispatch);

export default store;
