import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { useDeployMicroServiceMutation } from '../../redux/apis/MicroServiceApi'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const MicroServicesDeploy = ({ data, setOpenDeploy }) => {
    const initialValue = {
        hostname: "",
        server_stack: "",
        public_cloud_name: "",
        server_os: "",
        mysql_version: "",
        webserver: "",
        host_ip: "",
        ssh_username: "",
        ssh_port: "",
        r1soft: "",
        authentication_method: "",
        server_size: "",
        aws_user_access_key: "",
        aws_user_secret_key: "",
        server_region: "",
        server_plan: "",
        ssh_password: "",
        ssh_key: "",
        microservice_name: data?.microservice_name ?? ""
    };
    const [formData, setFormData] = useState(initialValue);
    const [deployInfinityPro, result] = useDeployMicroServiceMutation();

    useEffect(() => {
        if (result?.isSuccess && result?.data?.success) {
            setFormData({
                hostname: "",
                server_stack: "",
                public_cloud_name: "",
                server_os: "",
                mysql_version: "",
                webserver: "",
                host_ip: "",
                ssh_username: "",
                ssh_port: "",
                r1soft: "",
                authentication_method: "",
                server_size: "",
                aws_user_access_key: "",
                aws_user_secret_key: "",
                server_region: "",
                server_plan: "",
                ssh_password: "",
                ssh_key: "",
                microservice_name: data?.microservice_name ?? ""
            });
            setOpenDeploy(false);
        }
    }, [result, setOpenDeploy, data]);

    console.log(formData)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlSelectChange = (event) => {
        const { name, value } = event.target;
        if (name === 'server_stack') {
            setFormData({ ...initialValue, [name]: value, hostname: formData.hostname });
        } else if (name === 'public_cloud_name') {
            setFormData({ ...initialValue, [name]: value, hostname: formData.hostname, server_stack: formData.server_stack });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        deployInfinityPro({ ...formData, microservice_name: data?.microservice_name });
    };

    return (
        <Paper style={{ borderRadius: "12px" }} className='form-outers karuModal'>
            <Card className="main-container" elevation={3}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <CardHeader title={"Run Infinity Pro"} />
                    {/* <Button
                        variant="contained"
                        style={{ color: "#ffff", background: "#0a243d", height: "50%", marginTop: "5px", wordWrap: "break-word", textTransform: 'none' }}
                        onClick={() => navigate('/microservice/dashboard')}
                    >
                        Go To Dashboard
                    </Button> */}
                    <HighlightOffIcon className="closeicon" onClick={() => { setOpenDeploy(false); setFormData(initialValue); }} />
                </Box>
                <Divider sx={{ border: "0.1px solid black", margin: "0px 15px" }} />
                <CardContent>
                    <Box component="form" sx={{ width: '70%', padding: 2 }} onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Hostname:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    id="hostname"
                                    required
                                    label="Hostname"
                                    defaultValue="server1.helloworld.com"
                                    name="hostname"
                                    type="hostname"
                                    size="medium"
                                    autoComplete="hostname"
                                    value={formData.hostname}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Server Stack:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Server Stack</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="outlined"
                                            sx={{
                                                paddingTop: '12px',
                                                paddingBottom: '12px',
                                            }}
                                            name="server_stack"
                                            label="Select Server Stack"
                                            value={formData.server_stack}
                                            onChange={handlSelectChange}
                                            IconComponent={ArrowDropDownIcon}
                                        >
                                            <MenuItem value={'vmin'}>Vmin</MenuItem>
                                            <MenuItem value={'cpanel'}>Cpanel</MenuItem>
                                            <MenuItem value={'docker'}>Docker</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Public Cloud:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Public Cloud</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="outlined"
                                            sx={{
                                                paddingTop: '12px',
                                                paddingBottom: '12px',
                                            }}
                                            name="public_cloud_name"
                                            label="Select Public Cloud"
                                            value={formData.public_cloud_name}
                                            onChange={handlSelectChange}
                                        >
                                            <MenuItem value={'WebScootCloud'}>Webscoot Cloud</MenuItem>
                                            <MenuItem value={'aws-ec2'}>AWS EC2</MenuItem>
                                            <MenuItem value={'aws-ec2-user'}>AWS EC2 By User</MenuItem>
                                            <MenuItem value={'aws-lightsail'}>AWS Lightsail</MenuItem>
                                            <MenuItem value={'aws-lightsail-user'}>AWS Lightsail User</MenuItem>
                                            <MenuItem value={'digitalocean-droplet'}>Digitalocean Droplet</MenuItem>
                                            <MenuItem value={'unissh'}>UniSSH</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            {['vmin', 'docker']?.includes(formData.server_stack) && formData.public_cloud_name !== "" &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Server OS:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Server OS</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    variant="outlined"
                                                    sx={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '12px',
                                                    }}
                                                    label="Select Server OS"
                                                    name="server_os"
                                                    value={formData.server_os}
                                                    onChange={handlSelectChange}
                                                >
                                                    <MenuItem value={'22'}>Ubuntu 22.x</MenuItem>
                                                    <MenuItem value={'24'}>Ubuntu 24.x</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </>
                            }

                            {['vmin', 'docker']?.includes(formData.server_stack) && formData.public_cloud_name !== ""
                                &&
                                <>
                                    {/* MySQL Version */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>MySQL Version:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select MySQL Version</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    variant="outlined"
                                                    sx={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '12px',
                                                    }}
                                                    label="Select MySQL Version"
                                                    name="mysql_version"
                                                    value={formData.mysql_version}
                                                    onChange={handlSelectChange}
                                                >
                                                    <MenuItem value={'57'}>5.7</MenuItem>
                                                    <MenuItem value={'80'}>8.0</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    {/* Web Server */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Web Server:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Web Server</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    variant="outlined"
                                                    sx={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '12px',
                                                    }}
                                                    label="Select Web Server"
                                                    name="webserver"
                                                    value={formData.webserver}
                                                    onChange={handlSelectChange}
                                                >
                                                    <MenuItem value={'nginx'}>Nginx</MenuItem>
                                                    <MenuItem value={'apache'}>Apache</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </>
                            }

                            {['vmin', 'docker']?.includes(formData.server_stack) && formData.public_cloud_name === 'unissh'
                                &&
                                <>
                                    {/* Host IP */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Host IP:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            id="host_ip"
                                            label="Host IP"
                                            defaultValue="1.1.1.1"
                                            name="host_ip"
                                            type="host_ip"
                                            size="medium"
                                            autoComplete="host_ip"
                                            value={formData.host_ip}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }

                            {['aws-ec2', 'aws-ec2-user']?.includes(formData.public_cloud_name) &&
                                <>
                                    {/* Ec2 Instance Size */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium" }}>Ec2 Instance Size:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            id="server_size"
                                            label="Ec2 Instance Size"
                                            defaultValue="Size In GB: 128"
                                            name="server_size"
                                            type="server_size"
                                            size="medium"
                                            autoComplete="server_size"
                                            value={formData.server_size}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }

                            {['aws-ec2-user', 'aws-lightsail-user']?.includes(formData.public_cloud_name) &&
                                <>
                                    {/* AWS User Access Key */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium" }}>AWS User Access Key:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            id="aws_user_access_key"
                                            label="Enter Access Key"
                                            name="aws_user_access_key"
                                            type="aws_user_access_key"
                                            size="medium"
                                            autoComplete="aws_user_access_key"
                                            value={formData.aws_user_access_key}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    {/* AWS User Secret Key */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium" }}>AWS User Secret Key:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            id="aws_user_secret_key"
                                            label="Enter Secret Key"
                                            name="aws_user_secret_key"
                                            type="aws_user_secret_key"
                                            size="medium"
                                            autoComplete="aws_user_secret_key"
                                            value={formData.aws_user_secret_key}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }

                            {['WebScootCloud', 'unissh']?.includes(formData.public_cloud_name) &&
                                <>
                                    {/* R1soft Backup */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>R1soft Backup:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select R1soft Backup</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    variant="outlined"
                                                    sx={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '12px',
                                                    }}
                                                    label="Select R1soft Backup"
                                                    name="r1soft"
                                                    value={formData.r1soft}
                                                    onChange={handlSelectChange}
                                                >
                                                    <MenuItem value={'true'}>True</MenuItem>
                                                    <MenuItem value={'false'}>False</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {!['unissh', '']?.includes(formData.public_cloud_name) &&
                                <>
                                    {/* Server Region */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Server Region:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Server Region</InputLabel>
                                                {formData.public_cloud_name === 'WebScootCloud' &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Region"
                                                        name="server_region"
                                                        value={formData.server_region}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value={'IN13'}>IN13</MenuItem>
                                                        <MenuItem value={'IN12'}>IN12</MenuItem>
                                                        <MenuItem value={'IN03'}>IN03</MenuItem>
                                                        <MenuItem value={'IN07'}>IN07</MenuItem>
                                                        <MenuItem value={'IN11'}>IN11</MenuItem>
                                                    </Select>
                                                }
                                                {!['WebScootCloud', 'digitalocean-droplet']?.includes(formData.public_cloud_name) &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Region"
                                                        name="server_region"
                                                        value={formData.server_region}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value="us-east-1">us-east-1(N. Virginia)</MenuItem>
                                                        <MenuItem value="us-east-2">us-east-2(Ohio)</MenuItem>
                                                        <MenuItem value="us-west-1">us-west-1(N. California)</MenuItem>
                                                        <MenuItem value="us-west-2">us-west-2(Oregon)</MenuItem>
                                                        <MenuItem value="ca-central-1">ca-central-1(Central Canada)</MenuItem>
                                                        <MenuItem value="eu-central-1">eu-central-1(Frankfurt)</MenuItem>
                                                        <MenuItem value="eu-west-1">eu-west-1(Ireland)</MenuItem>
                                                        <MenuItem value="eu-west-2">eu-west-2(London)</MenuItem>
                                                        <MenuItem value="eu-west-3">eu-west-3(Paris)</MenuItem>
                                                        <MenuItem value="eu-north-1">eu-north-1(Stockholm)</MenuItem>
                                                        <MenuItem value="ap-east-1">ap-east-1(Hong Kong)</MenuItem>
                                                        <MenuItem value="ap-northeast-1">ap-northeast-1(Tokyo)</MenuItem>
                                                        <MenuItem value="ap-northeast-2">ap-northeast-2(Seoul)</MenuItem>
                                                        <MenuItem value="ap-northeast-3">ap-northeast-3(Osaka-Local)</MenuItem>
                                                        <MenuItem value="ap-southeast-1">ap-southeast-1(Singapore)</MenuItem>
                                                        <MenuItem value="ap-southeast-2">ap-southeast-2(Sydney)</MenuItem>
                                                        <MenuItem value="ap-south-1">ap-south-1(Mumbai)</MenuItem>
                                                        <MenuItem value="sa-east-1">sa-east-1(São Paulo)</MenuItem>
                                                    </Select>
                                                }
                                                {formData.public_cloud_name === "digitalocean-droplet" &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Region"
                                                        name="server_region"
                                                        value={formData.server_region}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value="nyc1">nyc1(New York)</MenuItem>
                                                        <MenuItem value="nyc2">nyc2(New York)</MenuItem>
                                                        <MenuItem value="nyc3">nyc3(New York)</MenuItem>
                                                        <MenuItem value="sfo1">sfo1(San Francisco)</MenuItem>
                                                        <MenuItem value="sfo2">sfo2(San Francisco))</MenuItem>
                                                        <MenuItem value="sfo3">sfo3(San Francisco))</MenuItem>
                                                        <MenuItem value="ams3">ams3(Amsterdam)</MenuItem>
                                                        <MenuItem value="ams2">ams2(Amsterdam)</MenuItem>
                                                        <MenuItem value="sgp1">sgp1(Singapore)</MenuItem>
                                                        <MenuItem value="lon1">lon1(London)</MenuItem>
                                                        <MenuItem value="fra1">fra1(Frankfurt)</MenuItem>
                                                        <MenuItem value="tor1">tor1(Toronto)</MenuItem>
                                                        <MenuItem value="blr1">blr1(Bangalore)</MenuItem>
                                                    </Select>
                                                }
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    {/* Server Plan */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Server Plan:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Server Plan</InputLabel>
                                                {['WebScootCloud']?.includes(formData.public_cloud_name) &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Plan"
                                                        name="server_plan"
                                                        value={formData.server_plan}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value={'Cloud-Growing'}>2vcpu-4gb-50GB</MenuItem>
                                                        <MenuItem value={'Cloud-Advanced'}>4vcpu-16gb-200GB</MenuItem>
                                                        <MenuItem value={'Cloud-Scale'}>8vcpu-32gb-400GB</MenuItem>
                                                    </Select>
                                                }

                                                {['aws-ec2', 'aws-ec2-user']?.includes(formData.public_cloud_name) &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Plan"
                                                        name="server_plan"
                                                        value={formData.server_plan}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value="t2.nano">t2.nano(0.5GB RAM, 1vCPU)</MenuItem>
                                                        <MenuItem value="t2.micro">t2.micro(1GB RAM, 1vCPU)</MenuItem>
                                                        <MenuItem value="t2.small">t2.small(2GB RAM, 1vCPU)</MenuItem>
                                                        <MenuItem value="t2.medium">t2.medium(4GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t2.large">t2.large(8GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t2.xlarge">t2.xlarge(16GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="t2.2xlarge">t2.2xlarge(32GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="t3.nano">t3.nano(0.5GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t3.micro">t3.micro(1GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t3.small">t3.small(2GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t3.medium">t3.medium(4GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t3.large">t3.large(8GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="t3.xlarge">t3.xlarge(16GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="t3.2xlarge">t3.2xlarge(32GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="m5.large">m5.large(8GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="m5.xlarge">m5.xlarge(16GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="m5.2xlarge">m5.2xlarge(32GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="m5.4xlarge">m5.4xlarge(64GB RAM, 16vCPU)</MenuItem>
                                                        <MenuItem value="m5.8xlarge">m5.8xlarge(128GB RAM, 32vCPU)</MenuItem>
                                                        <MenuItem value="m5.12xlarge">m5.12xlarge(192GB RAM, 48vCPU)</MenuItem>
                                                        <MenuItem value="m5.16xlarge">m5.16xlarge(256GB RAM, 64vCPU)</MenuItem>
                                                        <MenuItem value="m5.24xlarge">m5.24xlarge(384GB RAM, 96vCPU)</MenuItem>
                                                        <MenuItem value="m5.metal">m5.metal(96 to 384GB RAM, 24 to 96vCPU)</MenuItem>
                                                        <MenuItem value="m6a.large">m6a.large(8GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="m6a.xlarge">m6a.xlarge(16GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="m6a.2xlarge">m6a.2xlarge(32GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="m6a.4xlarge">m6a.4xlarge(64GB RAM, 16vCPU)</MenuItem>
                                                        <MenuItem value="m6a.8xlarge">m6a.8xlarge(128GB RAM, 32vCPU)</MenuItem>
                                                        <MenuItem value="c5.large">c5.large(4GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="c5.xlarge">c5.xlarge(8GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="c5.2xlarge">c5.2xlarge(16GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="c5.4xlarge">c5.4xlarge(32GB RAM, 16vCPU)</MenuItem>
                                                        <MenuItem value="c5.9xlarge">c5.9xlarge(72GB RAM, 36vCPU)</MenuItem>
                                                        <MenuItem value="c5.12xlarge">c5.12xlarge(96GB RAM, 48vCPU)</MenuItem>
                                                        <MenuItem value="c5.18xlarge">c5.18xlarge(144GB RAM, 72vCPU)</MenuItem>
                                                        <MenuItem value="c5.24xlarge">c5.24xlarge(192GB RAM, 96vCPU)</MenuItem>
                                                        <MenuItem value="c5.metal">c5.metal(192GB RAM, 96vCPU)</MenuItem>
                                                        <MenuItem value="c5a.large">c5a.large(4GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="c5a.xlarge">c5a.xlarge(8GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="c5a.2xlarge">c5a.2xlarge(16GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="c5a.4xlarge">c5a.4xlarge(32GB RAM, 16vCPU)</MenuItem>
                                                        <MenuItem value="c5a.8xlarge">c5a.8xlarge(64GB RAM, 32vCPU)</MenuItem>
                                                        <MenuItem value="c6a.large">c6a.large(4GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="c6a.xlarge">c6a.xlarge(8GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="c6a.2xlarge">c6a.2xlarge(16GB RAM, 8vCPU)</MenuItem>
                                                        <MenuItem value="c6a.4xlarge">c6a.4xlarge(32GB RAM, 16vCPU)</MenuItem>
                                                        <MenuItem value="c6a.8xlarge">c6a.8xlarge(64GB RAM, 32vCPU)</MenuItem>
                                                    </Select>
                                                }

                                                {['aws-lightsail', 'aws-lightsail-user']?.includes(formData.public_cloud_name) &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Plan"
                                                        name="server_plan"
                                                        value={formData.server_plan}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value="nano">nano(0.5GB RAM, 1vCPU)</MenuItem>
                                                        <MenuItem value="micro">micro(1GB RAM, 1vCPU)</MenuItem>
                                                        <MenuItem value="small">small(2GB RAM, 1vCPU)</MenuItem>
                                                        <MenuItem value="medium">medium(4GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="large">large(8GB RAM, 2vCPU)</MenuItem>
                                                        <MenuItem value="xlarge">xlarge(16GB RAM, 4vCPU)</MenuItem>
                                                        <MenuItem value="2xlarge">2xlarge(32GB RAM, 8vCPU)</MenuItem>
                                                    </Select>
                                                }
                                                {['digitalocean-droplet']?.includes(formData.public_cloud_name) &&
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        sx={{
                                                            paddingTop: '12px',
                                                            paddingBottom: '12px',
                                                        }}
                                                        label="Select Server Plan"
                                                        name="server_plan"
                                                        value={formData.server_plan}
                                                        onChange={handlSelectChange}
                                                    >
                                                        <MenuItem value="s-1vcpu-512mb-10gb">(0.5GB, 1 vCPUs, 10GB)</MenuItem>
                                                        <MenuItem value="s-1vcpu-1gb">(1.0GB, 1 vCPUs, 25GB)</MenuItem>
                                                        <MenuItem value="s-1vcpu-1gb-amd">(1.0GB, 1 vCPUs, 25GB)</MenuItem>
                                                        <MenuItem value="s-1vcpu-1gb-intel">(1.0GB, 1 vCPUs, 25GB)</MenuItem>
                                                        <MenuItem value="s-1vcpu-2gb">(2.0GB, 1 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="s-1vcpu-2gb-amd">(2.0GB, 1 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="s-1vcpu-2gb-intel">(2.0GB, 1 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="s-2vcpu-2gb">(2.0GB, 2 vCPUs, 60GB)</MenuItem>
                                                        <MenuItem value="s-2vcpu-2gb-amd">(2.0GB, 2 vCPUs, 60GB)</MenuItem>
                                                        <MenuItem value="s-2vcpu-2gb-intel">(2.0GB, 2 vCPUs, 60GB)</MenuItem>
                                                        <MenuItem value="s-2vcpu-4gb">(4.0GB, 2 vCPUs, 80GB)</MenuItem>
                                                        <MenuItem value="s-2vcpu-4gb-amd">(4.0GB, 2 vCPUs, 80GB)</MenuItem>
                                                        <MenuItem value="s-2vcpu-4gb-intel">(4.0GB, 2 vCPUs, 80GB)</MenuItem>
                                                        <MenuItem value="c-2">(4.0GB, 2 vCPUs, 25GB)</MenuItem>
                                                        <MenuItem value="c2-2vcpu-4gb">(4.0GB, 2 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="s-4vcpu-8gb">(8.0GB, 4 vCPUs, 160GB)</MenuItem>
                                                        <MenuItem value="s-4vcpu-8gb-amd">(8.0GB, 4 vCPUs, 160GB)</MenuItem>
                                                        <MenuItem value="s-4vcpu-8gb-intel">(8.0GB, 4 vCPUs, 160GB)</MenuItem>
                                                        <MenuItem value="g-2vcpu-8gb">(8.0GB, 2 vCPUs, 25GB)</MenuItem>
                                                        <MenuItem value="gd-2vcpu-8gb">(8.0GB, 2 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="m-2vcpu-16gb">(16.0GB, 2 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="c-4">(8.0GB, 4 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="c2-4vcpu-8gb">(8.0GB, 4 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="s-8vcpu-16gb">(16.0GB, 8 vCPUs, 320GB)</MenuItem>
                                                        <MenuItem value="m3-2vcpu-16gb">(16.0GB, 2 vCPUs, 150GB)</MenuItem>
                                                        <MenuItem value="c-4-intel">(8.0GB, 4 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="s-8vcpu-16gb-amd">(16.0GB, 8 vCPUs, 320GB)</MenuItem>
                                                        <MenuItem value="s-8vcpu-16gb-intel">(16.0GB, 8 vCPUs, 320GB)</MenuItem>
                                                        <MenuItem value="c2-4vcpu-8gb-intel">(8.0GB, 4 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="g-4vcpu-16gb">(16.0GB, 4 vCPUs, 50GB)</MenuItem>
                                                        <MenuItem value="so-2vcpu-16gb">(16.0GB, 2 vCPUs, 300GB)</MenuItem>
                                                        <MenuItem value="m6-2vcpu-16gb">(16.0GB, 2 vCPUs, 300GB)</MenuItem>
                                                        <MenuItem value="gd-4vcpu-16gb">(16.0GB, 4 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="so1_5-2vcpu-16gb">(16.0GB, 2 vCPUs, 450GB)</MenuItem>
                                                        <MenuItem value="m-4vcpu-32gb">(32.0GB, 4 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="c-8">(16.0GB, 8 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="c2-8vcpu-16gb">(16.0GB, 8 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="m3-4vcpu-32gb">(32.0GB, 4 vCPUs, 300GB)</MenuItem>
                                                        <MenuItem value="c-8-intel">(16.0GB, 8 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="c2-8vcpu-16gb-intel">(16.0GB, 8 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="g-8vcpu-32gb">(32.0GB, 8 vCPUs, 100GB)</MenuItem>
                                                        <MenuItem value="so-4vcpu-32gb">(32.0GB, 4 vCPUs, 600GB)</MenuItem>
                                                        <MenuItem value="m6-4vcpu-32gb">(32.0GB, 4 vCPUs, 600GB)</MenuItem>
                                                        <MenuItem value="gd-8vcpu-32gb">(32.0GB, 8 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="so1_5-4vcpu-32gb">(32.0GB, 4 vCPUs, 900GB)</MenuItem>
                                                        <MenuItem value="m-8vcpu-64gb">(64.0GB, 8 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="c-16">(32.0GB, 16 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="c2-16vcpu-32gb">(32.0GB, 16 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="m3-8vcpu-64gb">(64.0GB, 8 vCPUs, 600GB)</MenuItem>
                                                        <MenuItem value="c-16-intel">(32.0GB, 16 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="c2-16vcpu-32gb-intel">(32.0GB, 16 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="g-16vcpu-64gb">(64.0GB, 16 vCPUs, 200GB)</MenuItem>
                                                        <MenuItem value="so-8vcpu-64gb">(64.0GB, 8 vCPUs, 1200GB)</MenuItem>
                                                        <MenuItem value="m6-8vcpu-64gb">(64.0GB, 8 vCPUs, 1200GB)</MenuItem>
                                                        <MenuItem value="gd-16vcpu-64gb">(64.0GB, 16 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="so1_5-8vcpu-64gb">(64.0GB, 8 vCPUs, 1800GB)</MenuItem>
                                                        <MenuItem value="m-16vcpu-128gb">(128.0GB, 16 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="c-32">(64.0GB, 32 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="c2-32vcpu-64gb">(64.0GB, 32 vCPUs, 800GB)</MenuItem>
                                                        <MenuItem value="m3-16vcpu-128gb">(128.0GB, 16 vCPUs, 1200GB)</MenuItem>
                                                        <MenuItem value="c-32-intel">(64.0GB, 32 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="c2-32vcpu-64gb-intel">(64.0GB, 32 vCPUs, 800GB)</MenuItem>
                                                        <MenuItem value="c-48">(96.0GB, 48 vCPUs, 600GB)</MenuItem>
                                                        <MenuItem value="m-24vcpu-192gb">(192.0GB, 24 vCPUs, 600GB)</MenuItem>
                                                        <MenuItem value="g-32vcpu-128gb">(128.0GB, 32 vCPUs, 400GB)</MenuItem>
                                                        <MenuItem value="so-16vcpu-128gb">(128.0GB, 16 vCPUs, 2400GB)</MenuItem>
                                                        <MenuItem value="m6-16vcpu-128gb">(128.0GB, 16 vCPUs, 2400GB)</MenuItem>
                                                        <MenuItem value="gd-32vcpu-128gb">(128.0GB, 32 vCPUs, 800GB)</MenuItem>
                                                        <MenuItem value="c2-48vcpu-96gb">(96.0GB, 48 vCPUs, 1200GB)</MenuItem>
                                                        <MenuItem value="m3-24vcpu-192gb">(192.0GB, 24 vCPUs, 1800GB)</MenuItem>
                                                        <MenuItem value="g-40vcpu-160gb">(160.0GB, 40 vCPUs, 500GB)</MenuItem>
                                                        <MenuItem value="so1_5-16vcpu-128gb">(128.0GB, 16 vCPUs, 3600GB)</MenuItem>
                                                        <MenuItem value="c-48-intel">(96.0GB, 48 vCPUs, 600GB)</MenuItem>
                                                        <MenuItem value="m-32vcpu-256gb">(256.0GB, 32 vCPUs, 800GB)</MenuItem>
                                                        <MenuItem value="gd-40vcpu-160gb">(160.0GB, 40 vCPUs, 1000GB)</MenuItem>
                                                        <MenuItem value="c2-48vcpu-96gb-intel">(96.0GB, 48 vCPUs, 1200GB)</MenuItem>
                                                        <MenuItem value="so-24vcpu-192gb">(192.0GB, 24 vCPUs, 3600GB)</MenuItem>
                                                        <MenuItem value="m6-24vcpu-192gb">(192.0GB, 24 vCPUs, 3600GB)</MenuItem>
                                                        <MenuItem value="m3-32vcpu-256gb">(256.0GB, 32 vCPUs, 2400GB)</MenuItem>
                                                        <MenuItem value="so1_5-24vcpu-192gb">(192.0GB, 24 vCPUs, 5400GB)</MenuItem>
                                                        <MenuItem value="so-32vcpu-256gb">(256.0GB, 32 vCPUs, 4800GB)</MenuItem>
                                                        <MenuItem value="m6-32vcpu-256gb">(256.0GB, 32 vCPUs, 4800GB)</MenuItem>
                                                        <MenuItem value="so1_5-32vcpu-256gb">(256.0GB, 32 vCPUs, 7200GB)</MenuItem>
                                                    </Select>
                                                }
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {['unissh']?.includes(formData.public_cloud_name) &&
                                <>
                                    {/* Authentication Method */}
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium" }}>Authentication Method:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Authentication Method</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    variant="outlined"
                                                    sx={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '12px',
                                                    }}
                                                    label="Select Authentication Method"
                                                    name="authentication_method"
                                                    value={formData.authentication_method}
                                                    onChange={handlSelectChange}
                                                >
                                                    <MenuItem value={'password_base'}>Password Base</MenuItem>
                                                    <MenuItem value={'key_base'}>Key Base</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {formData?.authentication_method === 'password_base' &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>SSH Password:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            id="ssh_password"
                                            required
                                            label="Enter SSH Password"
                                            name="ssh_password"
                                            type="ssh_password"
                                            size="medium"
                                            autoComplete="ssh_password"
                                            value={formData.ssh_password}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </>
                            }
                            {formData?.authentication_method === 'key_base' &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>SSH Key:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            id="ssh_key"
                                            required
                                            label="Enter SSH Key"
                                            name="ssh_key"
                                            type="ssh_key"
                                            size="medium"
                                            autoComplete="ssh_key"
                                            value={formData.ssh_key}
                                            onChange={handleChange}
                                            variant="outlined"
                                            multiline
                                            minRows={4}
                                            maxRows={10}
                                        />
                                    </Grid>
                                </>
                            }

                            {/* Submit Button */}
                            <Grid item xs={12} style={{ display: "flex" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="save-btn"
                                    style={{ marginRight: "18px" }}
                                    onSubmit={handleSubmit}
                                >
                                    Deploy Now
                                </Button>
                                {data &&
                                    <Button
                                        type="button"
                                        variant="contained"
                                        className="close-btn"
                                        onClick={() => { setOpenDeploy(false); setFormData(initialValue); }}
                                    >
                                        Cancel
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Paper>
    )
}

export default MicroServicesDeploy