import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Backdrop, Box, Container, debounce, Fade, Modal, Paper, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material'
import { SearchElement } from '../../components/Search/Search'
import { TableHeadElement } from '../../components/Table/TableHeadElement'
import TableBodyElement from '../../components/Table/TableBodyElement'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDeleteMicroServiceMutation, useGetMicroServiceQuery } from '../../redux/apis/MicroServiceApi'
import AddEditService from './AddEditService'
import MicroServicesDeploy from './Deploy'
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox'

const tableColumns = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "microservice_name",
        sort: true,
        label: "Service Name",
    },
    {
        id: "pipeline_name",
        sort: true,
        label: "Pipeline Name",
    },
    {
        id: "pipeline_status",
        sort: false,
        label: "Pipeline Status"
    },
    {
        id: "job_name",
        sort: true,
        label: "Job Name",
    },
    {
        id: "job_status",
        sort: false,
        label: "Job Status"
    },
    {
        id: "fail_reason",
        sort: false,
        label: "Fail Reason"
    },
    {
        id: "trigger_by",
        sort: false,
        label: "Trigger By"
    },
    {
        id: "trigger_time",
        sort: false,
        label: "Trigger Time"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];
let initialOrder = process.env.REACT_APP_ORDER;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 10,
    borderRadius: 2,
};

const MicroServicesDashboard = () => {
    const initialState = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE) ?? 20,
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };

    let navigate = useNavigate();
    let query = useLocation();

    const [isClose, setIsClose] = useState(false);
    const [Id, setId] = useState("");
    const [openEdit, setOpenEdit] = useState(false);

    const [openDeploy, setOpenDeploy] = useState(false);
    const [deployServiceData, setDeployServiceData] = useState(null);

    const [orderBy, setOrderBy] = useState(process.env.REACT_APP_ORDER_BY);
    const [order, setOrder] = useState(initialOrder);
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(initialState.per_page);
    const [pageMetaData, setPageMetaData] = useState(initialState);
    const [microServiceListData, setMicroServiceListData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });

    const MicroServiceListData = useGetMicroServiceQuery({
        per_page: pageMetaData.per_page ?? rowsPerPage,
        page: query.state ?? pageMetaData.page,
        order: pageMetaData.order ?? order,
        order_by: pageMetaData.order_by ?? orderBy,
        searchText: pageMetaData.searchText ?? search,
    });
    const [deleteMicroService] = useDeleteMicroServiceMutation();

    useEffect(() => {
        // setMicroServiceListData({
        //     data: [{
        //         id: "1",
        //         microservice_name: "abc",
        //         pipeline_name: "103.33.44.223",
        //         trigger_by: "AVSZ",
        //         pipeline_status: 'true',
        //     }],
        //     last_page: 1,
        //     page: 0,
        //     total: 1,
        // })
        if (MicroServiceListData?.data?.success) {
            setMicroServiceListData(MicroServiceListData?.data?.data ?? [])
            setMicroServiceListData({
                data: MicroServiceListData?.data?.data?.data ?? [],
                page: MicroServiceListData?.data?.data?.current_page - 1,
                last_page: MicroServiceListData?.data?.data?.last_name,
                total: MicroServiceListData?.data?.data?.total,
            });
        }
    }, [MicroServiceListData]);

    const memoizedSetOpen = useCallback((value) => setOpenEdit(value), []);
    const memoizedSetId = useCallback((value) => setId(value), []);

    const memoizedSetOpenDeploy = useCallback((value) => setOpenDeploy(value), []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setPageMetaData({
            ...pageMetaData,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (event, property) => {
        if (property !== ("action")) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPageMetaData({ ...pageMetaData, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageMetaData({
            ...pageMetaData,
            per_page: parseInt(event.target.value),
            page: 1,
        });
    };

    const searchHandler = useMemo(() => {
        return debounce((event) => {
            setSearch(event.target.value);
            setPageMetaData({
                page: 1,
                per_page: pageMetaData?.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, [pageMetaData]);

    const confirmBoxClose = (res) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteMicroService(Id);
        } else {
            setIsClose(res);
        }
    };

    return (
        <Paper className="table-outers">
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 40%' }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    className="inner-headings"
                >
                    MicroService Dashboard
                </Typography>

                <div className="flex-boxinner">
                    <Typography
                        sx={{ flex: '1 1 auto' }}
                    >
                        <SearchElement
                            searchFn={searchHandler}
                            searchTag={`Search ...`}
                        />
                    </Typography>
                </div>
            </Toolbar>
            <StyledEngineProvider>
                <TableContainer component={Paper} className="table-outers">
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small" aria-label="a dense table"
                    >
                        <TableHead className='table-head'>
                            <TableRow>
                                <TableHeadElement
                                    setHead={tableColumns ? tableColumns : []}
                                    order={order}
                                    orderBy={orderBy}
                                    sortFn={(event, id) =>
                                        createSortHandler(event, id)
                                    }
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyElement
                                selectedColumns={[
                                    "microservice_name",
                                    "pipeline_name",
                                    "pipeline_status",
                                    "job_name",
                                    "job_status",
                                    "fail_reason",
                                    "trigger_by",
                                    "trigger_time"
                                ]}
                                setData={microServiceListData?.data ? microServiceListData?.data : []}
                                deployServiceFn={(data) => {
                                    setOpenDeploy(true);
                                    setDeployServiceData(data);
                                }}
                                editFn={(id) => { setId(id); setOpenEdit(true); }}
                                deleteFn={(id) => {
                                    setIsClose(true);
                                    setId(id);
                                }}
                                pageData={{
                                    limit:
                                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                    page: microServiceListData?.page,
                                }}
                            />
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={microServiceListData?.total ?? 0}
                        page={microServiceListData?.page ?? 0}
                        rowsPerPageOptions={[20, 50, 100]}
                        onPageChange={handleChangePage}
                        rowsPerPage={
                            rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                        }
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </StyledEngineProvider>
            {openEdit &&
                <Container maxWidth={false} className='p-0' >
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openEdit}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openEdit}>
                            <Box sx={style}>
                                <AddEditService id={Id} setOpen={memoizedSetOpen} setId={memoizedSetId} />
                            </Box>
                        </Fade>
                    </Modal>
                </Container>
            }

            {openDeploy &&
                <Container maxWidth={false} className='p-0' >
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openDeploy}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openDeploy}>
                            <Box sx={style}>
                                <MicroServicesDeploy data={deployServiceData} setOpenDeploy={memoizedSetOpenDeploy} />
                            </Box>
                        </Fade>
                    </Modal>
                </Container>
            }
            <ConfirmBox
                title="MicroService Delete Confirmation"
                message="Are you sure, you want to delete this MicroService?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </Paper>
    )
}

export default MicroServicesDashboard