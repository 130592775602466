import React, { useCallback } from 'react'
import { Button, TableCell, TableRow, Tooltip } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
    DeleteForeverRounded
} from "@mui/icons-material";

const TableBodyElement = (props) => {

    let tableColumnWidth = props.styleFn;

    const tableColumnsData = useCallback((columnsData) => {
        const variableType = ["object"];
        if (variableType.includes(typeof columnsData)) {
            return columnsData ? (columnsData).join(", ") : "";
        }
        return columnsData;
    }, []);

    const dateFormatter = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp);
        const year = date.getFullYear() ?? ' ';
        const month = (date.getMonth() + 1).toString().padStart(2, '0') ?? ' ';
        const day = date.getDate().toString().padStart(2, '0') ?? ' ';
        const formattedDateTime = `${day}-${month}-${year}`;
        return formattedDateTime;
    }, []);

    return (
        <>
            {(props.setData ? props.setData : []).map(
                (column, index) => (
                    <TableRow key={index}
                        style={
                            column.is_debited !== undefined
                                ? { backgroundColor: column.is_debited ? "#bf3030" : "#399861" }
                                : {}
                        }
                    >
                        {props.showSerialNumber !== false &&
                            <TableCell>
                                {props.pageData &&
                                    props.pageData?.limit * props.pageData?.page + index + 1}
                            </TableCell>
                        }
                        {props.selectedColumns.map((columnName, indx) => (
                            <TableCell
                                key={columnName}
                                align="left"
                                style={{ width: tableColumnWidth }}
                            >
                                {columnName === 'createdAt' ? dateFormatter(column[columnName]) : tableColumnsData(column[columnName])}
                            </TableCell>
                        ))}
                        {!props?.showAction && (
                            <TableCell align="left">
                                {typeof props.deployServiceFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) &&
                                    (column?.service_status === 1 ||
                                        column?.service_status === undefined) && (
                                        <Tooltip title="Run Deploy" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.deployServiceFn === "function" &&
                                                    props.deployServiceFn(column)
                                                }
                                                className="action-btn"
                                            >
                                                <RocketLaunchIcon />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                                {typeof props.editFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="Edit" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.editFn === "function" &&
                                                    props.editFn(column.id)
                                                }
                                                className="action-btn"
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                                {typeof props.deleteFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="Delete" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.deleteFn === "function" &&
                                                    props.deleteFn(column.id)
                                                }
                                                className="action-btn"
                                                style={{ color: "red" }}
                                            >
                                                <DeleteForeverRounded />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            {props?.setData?.length === 0 && (
                <TableRow>
                    <TableCell style={{ textAlign: "center", justifyContent: "center" }} colSpan={12}>
                        <h4>No Record found</h4>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default TableBodyElement